
























































































































































































































































































































































































































.AnnouncementPush {
  .operationControl {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: flex-start;
  }
  // .searchboxItem {
  //   display: flex;
  //   flex-direction: row;
  //   align-items: center;
  //   margin-top: 10px;

  // .el-cascader {
  //   height: 32px !important;
  // }
  //   .itemLabel {
  //     width: 150px;
  //     text-align: right;
  //   }
  // }
}
